:root {
  --color1: #000;
  --color2: #2b2b2d;
  --color3: #f7f7f7;
  --color4: #f3f3f3;
  --color5: #ebebeb;
  --color6: #231f20;
  --color7: rgba(77, 77, 77, 0.12);
  --color8: #4d4d4d;
  --color9: #b2aeab;
}
.loader {
  border: 16px solid var(--color4);
  border-radius: 50%;
  border-top: 16px solid var(--color1);
  border-bottom: 16px solid var(--color1);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: 40px auto;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.details {
  color: var(--color8);
}
.details img {
  width: 24px;
  height: 24px;
}
.report_border {
  background: var(--color7);
  height: 2px;
  width: 100%;
  z-index: 1;
}
h2 {
  font-size: 2rem;
  font-weight: 700;
}
h4 {
  font-size: 1.3rem;
}
h3 {
  font-weight: 700;
  font-size: 1.3em;
}
h5 {
  font-size: 0.8em;
  font-weight: 700;
}
span {
  font-size: 0.8em;
}
svg{
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: inline-block;
}